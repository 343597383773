import React from 'react';

export default function Footer(){
    return (
        <footer className="mastfoot text-center">
            <div className="row">
                <div className="col col-12">
                    Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış Tic. Dnş. Ltd. Şti.<br />
                    Barbaros Mahallesi Troya Caddesi No: 40-42 Merkez/Çanakkale
                </div>
                <div className="col col-6 text-right mt-2">
                    Tel: 0286 217 0202
                </div>
                <div className="col col-6 text-left mt-2">
                    Fax: 0286 217 0545
                </div>
                <div className="col col-12 text-center mt-2">
                    Çanakkale V.D. 649 043 0026
                </div>
            </div>
        </footer>
    )
}