import React from 'react';

export default function ElectronicCommercial(){
    return (
        <div className="information text-left">
            <p><span><strong>Elektronik Ticari İleti A&ccedil;ık Rıza Metni<br /><br /></strong></span>Otto Solmaz Ayakkabı Deri &Uuml;r. Tur. San. Dış Tic. Dnş. Ltd. Şti. tarafından temsil edilen markalarla ilgili satış, promosyon, pazarlama, reklam, indirim, tanıtım, bilgilendirme, kampanya vb diğer faydaların sunulması ama&ccedil;lı kısa mesaj, e-posta, mektup ve telefon kanalları yoluyla tarafıma ticari elektronik ileti g&ouml;nderilmesine izin verdiğimi kabul, beyan ve taahh&uuml;t ederim.&nbsp;</p>
            <p><b class="tinymceVarsayilan">Kişisel Verilerin Korunması Mevzuatı Uyarınca M&uuml;şteri A&ccedil;ık Rıza Metni</b></p>
            <p>&Ouml;zel nitelikli kişisel verilerimi de i&ccedil;eren kimlik kartı verileri ve sair kişisel verilerimin Otto Solmaz Ayakkabı Deri &Uuml;r. Tur. San. Dış Tic. Dnş. Ltd. Şti. tarafından sağlanan satım ve satış sonrası hizmetler &ccedil;er&ccedil;evesinde; &uuml;r&uuml;n ve hizmetlere ilişkin y&ouml;nlendirmede bulunulması, kampanyalara ilişkin bilgi verilebilmesi, satın alınan &uuml;r&uuml;n ve diğer hizmetlere ilişkin olarak memnuniyetimin değerlendirilmesine y&ouml;nelik analizler yapılması ve bu kapsamda tarafımla iletişime ge&ccedil;ilmesi, anılan hizmetlere ve &uuml;r&uuml;nlere y&ouml;nelik tanıtım, pazarlama ve kampanya faaliyetlerinin sosyal medya, arama motorları, e-mail, kısa mesaj vb. kanallarla ger&ccedil;ekleştirilmesi amacıyla tarafımla iletişime ge&ccedil;ilmesi ve aynı ama&ccedil;larla verilerimin yurt i&ccedil;i veya yurt dışı merkezli dijital pazarlama firmalarına aktarılması, &ouml;zel g&uuml;nlerde tarafıma hediye g&ouml;nderilebilmesi i&ccedil;in &uuml;r&uuml;n/hizmet sağlayan şirketlere aktarılması amacıyla yukarıda belirtilen bilgiler kapsamında işlenmesini kendi a&ccedil;ık rızam ile onaylıyorum.</p>
            <p>İletişim tercihlerimi istediğim her zaman ve hi&ccedil;bir gerek&ccedil;e g&ouml;stermeksizin değiştirebileceğim, g&ouml;ndereceğiniz elektronik iletilerde (ya da varsa yetkili mercilerce kurulan/kurdurulan sistemde)<span>&nbsp;</span><a href="mailto:kvkk@ottosolmaz.com">kvkk@ottosolmaz.com</a><span>&nbsp;</span>mail adresine veya 0850 226 0 444 Nolu numaraya red bildiriminde bulunarak ilgili ticari elektronik iletişimi durdurabileceğim, ancak yasal olarak zorunlu iletişimlerin her hal&uuml;k&acirc;rda devam edeceği hususlarında bilgilendirildim.</p>
        </div>
    )
}