import React from "react";

export default function KvkkInformation() {
    return (
        <div>
            <p>
                <strong>
                    KVKK Aydınlatma Metni
                </strong>
            </p>
            <ul>
                <li>
                    <strong>
                        Gizlilik ve Kişisel Verilerin Korunması Politikası
                    </strong>
                </li>
            </ul>
            <p>
                Kişisel verilerin korunması Otto Solmaz Ayakkabı Deri &Uuml;R.TUR.SAN.DIŞ TİC.DNŞ.LTD.ŞTİ. (&ldquo;OTTO
                SOLMAZ&rdquo;) i&ccedil;in &ouml;nemli bir konudur. OTTO SOLMAZ, 6698 Sayılı Kişisel Verilerin Korunması
                Kanunu&rsquo;na (&ldquo;KVK Kanunu&rdquo;) uyum sağlanması i&ccedil;in KVK
                Kanunu&rsquo;nun &ouml;ng&ouml;rd&uuml;ğ&uuml; ilkeleri benimsemekte, kişisel verilerin işlenmesi,
                silinmesi, yok edilmesi, anonim hale getirilmesi, aktarılması, ilgili kişinin aydınlatılması ve veri
                g&uuml;venliğinin sağlanmasıyla ilgili y&uuml;k&uuml;ml&uuml;l&uuml;klerini yerine getirmektedir. Bu
                kapsamda d&uuml;zenlenen Gizlilik ve Kişisel Verilerin Korunması Politikası kişisel verisi işlenen
                ger&ccedil;ek kişilerin (&ldquo;İlgili Kişi&rdquo;) erişimine sunulmaktadır.
                <br/>
                <br/>
                Amacımız; 6698 sayılı KVK Kanunu&rsquo;nun 10. maddesi gereğince ve sizlerin memnuniyeti doğrultusunda,
                kişisel verilerinizin alınma şekilleri, işlenme ama&ccedil;ları, paylaşılan kişiler, hukuki nedenleri ve
                haklarınız konularında sizi en şeffaf şekilde bilgilendirmektir.
            </p>
            <ul>
                <li>
                    <strong>
                        Kişisel Verileri Toplama Y&ouml;ntemleri ve Hukuki Sebepleri
                    </strong>
                </li>
            </ul>
            <p>
                OTTO SOLMAZ kişisel verileri Websiteleri, Websitelerinin mobil uygulamaları, sosyal medya
                hesapları, &ccedil;erezler, &ccedil;ağrı merkezi, idari ve adli makamlardan gelen tebligatlar ve sair
                iletişim kanalları aracılığıyla işitsel, elektronik veya yazılı olarak, KVK Kanunu&rsquo;nunda
                belirtilen kişisel veri işleme şartlarına uygun olarak ve işbu Gizlilik/Kişisel Verilerin Korunması
                Politikası&rsquo;nda belirtilen hukuki sebepler doğrultusunda toplamaktadır.
            </p>
            <ul>
                <li>
                    <strong>
                        Veri Konusu Kişi Grubu Kategorizasyonu
                    </strong>
                </li>
            </ul>
            <p>
                OTTO SOLMAZ kişisel veri işleme s&uuml;re&ccedil;lerinde ve bu s&uuml;re&ccedil;lere bağlı faaliyetlerde
                kişisel verileri işlenen veri konusu kişi gruplarını kategorize etmektedir. Bununla beraber KVK
                Kanunu&rsquo;nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartlarına uygun olarak ve işbu
                Gizlilik/Kişisel Verilerin Korunması Politikası&rsquo;nda belirtilen hukuki sebepler doğrultusunda sair
                kişi gruplarının da (danışman, eğitimci, blogger) kişisel verileri işlenebilmektedir.
            </p>
            <ul>
                <ol>
                    <li>
                        <strong>
                            M&uuml;şteri/&Uuml;ye, Potansiyel M&uuml;şteri/&Uuml;ye
                        </strong>
                    </li>
                    <ul>
                        <li>
                            <strong>
                                Kimlik Bilgisi:
                            </strong>
                            &nbsp;Ad, soyadı, doğum tarihi, cinsiyet, T.C. kimlik numarası
                        </li>
                        <li>
                            <strong>
                                Lokasyon Bilgisi:
                            </strong>
                            &nbsp;Yaşadığı şehir, il&ccedil;e (ayakkabionline.com aracılığıyla yapılan alışverişin
                            teslimat adresi)
                        </li>
                        <li>
                            <strong>
                                İletişim Bilgisi:
                            </strong>
                            &nbsp;cep telefonu, e-posta adresi, adres, posta kodu, sabit telefon
                        </li>
                        <li>
                            <strong>
                                Finansal Bilgi:
                            </strong>
                            &nbsp;Vergi dairesi, fatura bilgileri
                        </li>
                        <li>
                            <strong>
                                M&uuml;şteri/&Uuml;ye Bilgisi:
                            </strong>
                            &nbsp;&Uuml;yelik bilgisi, &uuml;yelik ID numarası
                        </li>
                        <li>
                            <strong>
                                M&uuml;şteri/&Uuml;ye İşlem Bilgisi:
                            </strong>
                            &nbsp;Satın alınan &uuml;r&uuml;n/ler, alışveriş tutarı, alışveriş tarihi, &ccedil;ağrı
                            merkezi g&ouml;r&uuml;şme kayıtları, ticari iletişim izni, faydalanılan kampanyalar,
                            kullanılan kuponlar, siparişe ilişkin bilgiler
                        </li>
                        <li>
                            <strong>
                                Risk Y&ouml;netimi Bilgisi:
                            </strong>
                            &nbsp;IP adresi
                        </li>
                        <li>
                            <strong>
                                İşlem G&uuml;venliği Bilgisi:
                            </strong>
                            &nbsp;Şifre, parola bilgileri
                        </li>
                        <li>
                            <strong>
                                Pazarlama Bilgisi:
                            </strong>
                            &nbsp;&Ccedil;erez kayıtları, hedefleme bilgileri, alışkanlık ve beğenileri g&ouml;steren
                            değerlendirmeler
                        </li>
                        <li>
                            <strong>
                                İşitsel Veri:
                            </strong>
                            &nbsp;&Ccedil;ağrı merkezi g&ouml;r&uuml;şme kayıtları
                        </li>
                        <li>
                            <strong>
                                Hukuki İşlem ve Uyum Bilgisi:
                            </strong>
                            &nbsp;Verilen hizmetin başlama ve bitiş zamanı, yararlanılan hizmetin t&uuml;r&uuml;,
                            aktarılan veri miktarı, İlgili Kişi&rsquo;nin elektronik ortamda verdiği ticari elektronik
                            ileti izni, onay verdiği &uuml;yelik s&ouml;zleşmesi, kurumsal &uuml;yelik s&ouml;zleşmesi,
                            mesafeli satış s&ouml;zleşmesi ve OTTO SOLMAZ tarafından sunulan hizmetlerden
                            faydalanılmasını sağlayan sair hukuki metinler ve s&ouml;zleşmeler
                        </li>
                        <li>
                            <strong>
                                Pazarlama Bilgisi:
                            </strong>
                            &nbsp;İlgili kişinin verdiği ticari elektronik ileti iznine istinaden g&ouml;nderilen
                            pazarlama ama&ccedil;lı sms, e-posta mesajları veya &ccedil;ağrı merkezi tarafından yapılan
                            aramalar
                        </li>
                        <li>
                            <strong>
                                Talep/Şikayet Y&ouml;netimi/İtibar Y&ouml;netimi Bilgisi:
                            </strong>
                            &nbsp;İlgili kişinin satın almış olduğu &uuml;r&uuml;n veya hizmete ilişkin web sitesi,
                            mobil aplikasyon, sosyal medya hesapları veya &ccedil;ağrı merkezi &uuml;zerinden ilettiği
                            şikayet ve/veya talepler ile bu istemlerin değerlendirilmesi veya y&ouml;netimi
                            s&uuml;recinde yapılan işlemlere ilişkin kayıtlar
                        </li>
                    </ul>
                    <li>
                        <strong>
                            &Ccedil;evrimi&ccedil;i Ziyaret&ccedil;i
                        </strong>
                    </li>
                    <ul>
                        <li>
                            <strong>
                                İşlem G&uuml;venliği Bilgisi:
                            </strong>
                            &nbsp;Şifre, cep telefonu, parola bilgileri
                        </li>
                        <li>
                            <strong>
                                Hukuki İşlem Bilgisi/Risk Y&ouml;netimi Bilgisi:
                            </strong>
                            &nbsp;IP adresi
                        </li>
                        <li>
                            <strong>
                                Hukuki İşlem ve Uyum Bilgisi:
                            </strong>
                            &nbsp;Verilen hizmetin başlama ve bitiş zamanı, yararlanılan hizmetin t&uuml;r&uuml;,
                            aktarılan veri miktarı.
                        </li>
                    </ul>
                    <li>
                        <strong>
                            Satın Alınan &Uuml;r&uuml;n&uuml;n Adına Teslim Edileceği Kişi
                        </strong>
                    </li>
                    <ul>
                        <li>
                            <strong>
                                Kimlik Bilgisi:
                            </strong>
                            &nbsp;Ad, soyadı, doğum tarihi, cinsiyet, T.C. kimlik numarası
                        </li>
                        <li>
                            <strong>
                                Lokasyon Bilgisi:
                            </strong>
                            &nbsp;Yaşadığı şehir, il&ccedil;e (ayakkabionline.com aracılığıyla yapılan alışverişin
                            teslimat adresi)
                        </li>
                        <li>
                            <strong>
                                İletişim Bilgisi:
                            </strong>
                            &nbsp;cep telefonu, e-posta adresi, adres, posta kodu, sabit telefon
                        </li>
                        <li>
                            <strong>
                                Finansal Bilgi:
                            </strong>
                            &nbsp;Vergi dairesi, fatura bilgileri
                        </li>
                    </ul>
                </ol>
                <li>
                    <strong>
                        Kişisel Verilerin Hangi Ama&ccedil;la İşleneceği
                    </strong>
                </li>
            </ul>
            <p>
                OTTO SOLMAZ tarafından, m&uuml;şterileri, &ccedil;alışanları, potansiyel
                m&uuml;şterileri, &ccedil;alışan adayları, iş ortakları ve tedarik&ccedil;ileri gibi taraflardan, kimlik
                bilgisi, iletişim bilgisi, m&uuml;şteri bilgisi, m&uuml;şteri işlem bilgisi, işlem g&uuml;venliği
                bilgisi, hukuki işlem ve uyum bilgisi ile pazarlama satış bilgisi gibi kategorilerde kişisel veri
                toplanabilmektedir.
                <br/>
                <br/>
                Toplanan kişisel verileriniz;
            </p>
            <ul>
                <ul>
                    <li>
                        OTTO SOLMAZ &uuml;r&uuml;n ve hizmetlerinin sizlere sunulabilmesi, sizlere karşı olan
                        y&uuml;k&uuml;ml&uuml;l&uuml;klerimizin yerine getirilmesi, kayıt ve belgelerin
                        d&uuml;zenlenebilmesi, yerel ve uluslararası yasal mevzuatın &ouml;ng&ouml;rd&uuml;ğ&uuml; bilgi
                        saklama, raporlama, bilgilendirme, vergi ve sair y&uuml;k&uuml;ml&uuml;l&uuml;klere uymak,
                    </li>
                    <li>
                        Hizmet ve &uuml;r&uuml;nlerin kalitesinin artırılmasına y&ouml;nelik yapılacak olan satış ve
                        pazarlama faaliyetleri i&ccedil;in yapılacak size &ouml;zel reklam, kampanya, avantajlar ve
                        diğer faydaları sunmak,
                    </li>
                    <li>
                        Bilgi işlem gereksinimleri, sistemsel yapısı, alınan bilgi işlem destek hizmetlerinin
                        gerekliliği, bu hizmet ve &uuml;r&uuml;nlere ilişkin olarak sizlere gerekli bilgilerin
                        aktarılması amacıyla iletişim kurmak,
                    </li>
                    <li>
                        Satış ve pazarlama faaliyetleri i&ccedil;in yapılacak trafik &ouml;l&ccedil;&uuml;mleme,
                        istatistiki analizler, Segmentasyon/profilleme ve CRM (M&uuml;şteri ilişkileri
                        y&ouml;netimi) &ccedil;alışmalarını y&uuml;r&uuml;tmek,
                    </li>
                    <li>
                        M&uuml;şteri memnuniyetinin &ouml;l&ccedil;&uuml;mlenmesi ve artırılması, şik&acirc;yet
                        y&ouml;netimi, yeni hizmet ve &uuml;r&uuml;nler ile ilgili g&ouml;r&uuml;ş ve &ouml;nerilerinizi
                        almak, sorun-hata bildirimlerinizi almak, &uuml;r&uuml;n ve hizmetlere, şikayet ve taleplerinize
                        y&ouml;nelik tarafınıza bilgi vermek,
                    </li>
                    <li>
                        Online satış ile ilgili &uuml;yeliğinizi y&ouml;netmek, siparişlerinizi almak, &ouml;deme
                        işlemlerinizi ger&ccedil;ekleştirmek, 3. kişiler ile lojistik iş birliği sağlayıp &uuml;r&uuml;n
                        g&ouml;nderimini sağlamak, ilginizi &ccedil;ekebilecek &uuml;r&uuml;n ve
                        hizmetleri &ouml;nermek, online davranışsal reklamcılık ve pazarlama, m&uuml;şteri portf&ouml;y
                        y&ouml;netimi, hizmet kalitesinin &ouml;l&ccedil;&uuml;lmesi ve geliştirilmesi, iletişim,
                        optimizasyon, denetim, risk y&ouml;netimi ve kontrol, promosyon, analiz, ilgi alanları
                        belirleme, skorlama, profilleme, pazarlama, satış, reklam
                    </li>
                    <li>
                        Karşılaştırmalı &uuml;r&uuml;n ve/veya hizmet teklifi, modelleme, mevcut veya
                        yeni &uuml;r&uuml;n &ccedil;alışmaları ve/veya geliştirmeleri, kişisel verilerinizi OTTO
                        SOLMAZ&rsquo;a a&ccedil;ıklamanıza konu olan OTTO SOLMAZ ana s&ouml;zleşmesinde yazılı olan
                        işleri d&uuml;zenleyen kanun ve ilgili mevzuat kapsamında sizlere sunulacak her
                        t&uuml;rl&uuml; &uuml;r&uuml;n ve hizmetlerde kullanılmak,
                    </li>
                    <li>
                        Resm&icirc; kurumlarca &ouml;ng&ouml;r&uuml;len bilgi saklama, raporlama, bilgilendirme
                        y&uuml;k&uuml;ml&uuml;l&uuml;klerine uymak, s&ouml;zleşmelerin gerekliliklerini yerine getirmek
                        ve bu hizmetlerden faydalanılmasına ilişkin olarak OTTO SOLMAZ&rsquo;ın tabi olduğu yasal
                        y&uuml;k&uuml;ml&uuml;l&uuml;kleri ifa etmek,
                    </li>
                    <li>
                        OTTO SOLMAZ&rsquo;ın ticari ve iş stratejilerinin belirlenmesi ve uygulanması amacı
                        doğrultusunda; OTTO SOLMAZ tarafından y&uuml;r&uuml;t&uuml;len finans operasyonları, iletişim,
                        pazar araştırması ve sosyal sorumluluk aktiviteleri, satın alma operasyonları (talep, teklif,
                        değerlendirme, sipariş, b&uuml;t&ccedil;elendirme, s&ouml;zleşme), şirket i&ccedil;i sistem ve
                        uygulama y&ouml;netimi operasyonları, hukuki operasyonları y&ouml;netmek
                    </li>
                    <li>
                        Resmi makamlardan veya sizlerden gelen talepleri incelemek, değerlendirmek ve yanıtlamak,
                        ama&ccedil;larıyla 6698 sayılı Kanun&rsquo;un 5. ve 6. maddelerinde belirtilen kişisel veri
                        işleme şartları ve ama&ccedil;ları dahilinde işlenecektir.
                    </li>
                </ul>
            </ul>
            <ul>
                <li>
                    <strong>
                        İşlenen Kişisel Verilerin Kimlere ve Hangi Ama&ccedil;la Aktarılabileceği
                    </strong>
                </li>
            </ul>
            <p>
                Toplanan kişisel verileriniz; yukarıda belirtilen ama&ccedil;ların ger&ccedil;ekleştirilmesi ile sınırlı
                olmak &uuml;zere;
            </p>
            <ul>
                <ul>
                    <li>
                        OTTO SOLMAZ&rsquo;ın iş ortaklarına, hissedarlarına, iştiraklerine,
                    </li>
                    <li>
                        T&uuml;rk Ticaret Kanunu, Bor&ccedil;lar Kanunu ve diğer mevzuat h&uuml;k&uuml;mlerinin izin
                        verdiği kişi veya kuruluşlara,
                    </li>
                    <li>
                        Kanunen yetkili kamu kurum ve kuruluşları, idari merciler ve yasal mercilere,
                    </li>
                    <li>
                        Yurtdışı şirketlerine ve iştiraklerine,
                    </li>
                    <li>
                        &Uuml;r&uuml;n/hizmet karşılaştırma, analiz, değerlendirme, reklam ve yukarıda belirtilen
                        ama&ccedil;ların ger&ccedil;ekleştirilmesinde hizmet aldığımız, işbirliği yaptığımız
                        ger&ccedil;ek veya t&uuml;zel kişilere, program ortağı kurum ve kuruluşlara,
                        m&uuml;şterilerimize g&ouml;nderdiğimiz iletilerin g&ouml;nderilmesi konusunda anlaşmalı
                        olduğumuz kurumlara, online mağazamızdan verilen siparişlerin size teslimini
                        ger&ccedil;ekleştiren kargo şirketlerine 6698 sayılı Kanun&rsquo;un 8. ve 9. maddelerinde
                        belirtilen kişisel veri işleme şartları ve ama&ccedil;ları &ccedil;er&ccedil;evesinde
                        aktarılabilecektir.
                    </li>
                </ul>
            </ul>
            <ul>
                <li>
                    <strong>
                        Kişisel Veri Toplamanın Y&ouml;ntemi ve Hukuki Sebebi
                    </strong>
                </li>
            </ul>
            <p>
                Kişisel verileriniz, mağazalarımız, internet sitemiz ve mobil uygulamamız, &ccedil;ağrı merkezlerimiz,
                sosyal medya hesaplarımız gibi mecralardan s&ouml;zl&uuml;, yazılı veya elektronik ortamda veya ilerde
                kurulacak/oluşabilecek diğer kanallar başta olmak &uuml;zere;
                <br/>
                <br/>
                OTTO SOLMAZ tarafından yasal mevzuat &ccedil;er&ccedil;evesinde yukarıda belirtilen ama&ccedil;larla,
                s&ouml;zleşmenin ifası dahilinde toplanmaktadır.
                <br/>
                <br/>
                Profilleme ve Segmentasyon
                <br/>
                OTTO SOLMAZ M&uuml;şteri/&Uuml;ye&rsquo;ye ilişkin olarak işlediği kişisel verileri kullanarak;
            </p>
            <ul>
                <ul>
                    <li>
                        Ticari elektronik ileti alma konusunda onay veren M&uuml;şteri/&Uuml;ye&rsquo;ye ilişkin olarak,
                        M&uuml;şteri/&Uuml;ye&rsquo;nin beğeni ve tercihlerine daha uygun i&ccedil;erik hazırlanması,
                        reklam, tanıtım, indirim yapılabilmesi amacıyla profilleme ve segmentasyon yapmaktadır.
                    </li>
                    <li>
                        Ticari elektronik ileti onayı vermemiş olan M&uuml;şteri/&Uuml;ye bakımından da profilleme ve
                        segmentasyon yapılarak;
                    </li>
                    <ul>
                        <li>
                            &Uuml;r&uuml;n iyileştirmesi yapılması (en &ccedil;ok satılan veya satılmayan &uuml;r&uuml;n
                            kategorilerinin belirlenmesi),
                        </li>
                        <li>
                            Alışveriş tercihlerinin analiz edilmesi suretiyle modellemeler yapılarak belirli
                            bir &uuml;r&uuml;n&uuml; alma potansiyeli olan m&uuml;şteri gruplarına y&ouml;nelik
                            kampanyalar d&uuml;zenlenmesi ve sisteme y&uuml;klenmesi,
                        </li>
                        <li>
                            Satış potansiyelinin artırılmasına y&ouml;nelik aksiyonlar alınması gibi &ccedil;alışmalar
                            yapılmaktadır.
                        </li>
                    </ul>
                </ul>
            </ul>
            <p>
                Profilleme ve segmentasyon &ccedil;alışmaları kapsamında M&uuml;şteri/&Uuml;ye&rsquo;nin kişisel
                verileri &ouml;zellikle ad ve soyadı, cep telefonu, e-postası veya adres bilgisi doğrudan
                kullanılmamakta, bunun yerine kendilerine atanan M&uuml;şteri/&Uuml;ye ID&rsquo;leri ile işlem
                yapılmaktadır. M&uuml;şteri ID&rsquo;si veya diğer bir ifade ile takma adlı veri kullanımı sayesinde
                M&uuml;şteri/&Uuml;ye&rsquo;nin kişisel verilerinin korunması sağlanmaktadır. M&uuml;şteri/&Uuml;ye
                ID&rsquo;leri OTTO SOLMAZ i&ccedil;inde sadece ilgili kişi veya departmanların erişimine a&ccedil;ıktır.
                M&uuml;şteri/&Uuml;ye&rsquo;ye atanan bu ID&rsquo;ler OTTO SOLMAZ tarafından sistem i&ccedil;inde
                şifreli olarak muhafaza edilmekte ve bu b&ouml;l&uuml;me erişim yine sadece sınırlı kişilere
                tanınmaktadır.
            </p>
            <ul>
                <li>
                    <strong>
                        İlgili Kişiler&rsquo;in Elektronik Ticari İleti Alma Konusundaki Olumlu Veya Olumsuz
                        Tercihlerini Nasıl Değiştirebilecekleri
                    </strong>
                </li>
            </ul>
            <p>
                OTTO SOLMAZ tarafından işletilen elektronik ticaret platformlarının web sitesi veya mobil
                uygulamasına &uuml;ye olurken veya daha sonraki bir zamanda vermiş olduğunuz ticari elektronik ileti
                alma konusundaki olumlu veya olumsuz tercihlerinizi dilediğiniz
                zaman &ldquo;Hesabım&rdquo; b&ouml;l&uuml;m&uuml;ne erişerek değiştirebilir veya
                g&uuml;ncelleyebilirsiniz.
                <br/>
                <br/>
                &Uuml;yeliğin sona erdirilmesi, ticari elektronik ileti alma konusunda verdiğiniz onayın geri alınması
                anlamına gelmemektedir. Bu nedenle ayrıca verdiğiniz onayı geri almaya ilişkin t&uuml;m işlemleri
                tamamladığınızdan emin olunuz.
            </p>
            <ul>
                <li>
                    <strong>
                        Resmi Makamlarla Kişisel Veri Paylaşımı
                    </strong>
                </li>
            </ul>
            <p>
                OTTO SOLMAZ, OTTO SOLMAZ tarafından işletilen elektronik ticaret platformlarına yaptığınız ziyaret
                veya &uuml;yeliğe ilişkin kişisel verilerinizi ve gezinme bilgileriniz gibi trafik bilgilerinizi; OTTO
                SOLMAZ&rsquo;ın yasalar karşısındaki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; ifa etmesi amacıyla
                (su&ccedil;la m&uuml;cadele, devlet ve kamu g&uuml;venliğinin tehdidi ve benzeri ancak bununla sınırlı
                olmamak &uuml;zere OTTO SOLMAZ&rsquo;ın yasal veya idari olarak bildirim veya bilgi verme
                y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n mevcut olduğu durumlarda) yasal olarak bu bilgileri talep
                etmeye yetkili olan kamu kurum ve kuruluşları ile paylaşabilecektir.
                <br/>
                <br/>
                <strong>
                    Gizlilik/Kişisel Verilerin Korunması Politikası&rsquo;nda Yapılacak Değişiklikler
                </strong>
                <br/>
                OTTO SOLMAZ, işbu Gizlilik/Kişisel Verilerin Korunması Politikası&rsquo;nda her zaman değişiklik
                yapabilir. Bu değişiklikler, değiştirilmiş yeni Gizlilik/Kişisel Verilerin Korunması
                Politikası&rsquo;nın yayınlanmasıyla birlikte derhal ge&ccedil;erlilik kazanır. İşbu Gizlilik/Kişisel
                Verilerin Korunması Politikası&rsquo;ndaki değişikliklerden haberdar olmanız i&ccedil;in,
                siz &uuml;yelerimize gerekli bilgilendirme yapılacaktır.
            </p>
            <ul>
                <li>
                    <strong>
                        Kişisel Veri Sahibinin 6698 sayılı Kanun&rsquo;un 11. Maddesinde Sayılan Hakları
                    </strong>
                </li>
            </ul>
            <p>
                Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi aşağıda d&uuml;zenlenen
                y&ouml;ntemlerle iletmeniz durumunda OTTO SOLMAZ talebin niteliğine g&ouml;re talebi en kısa s&uuml;rede
                ve en ge&ccedil; otuz g&uuml;n i&ccedil;inde sonu&ccedil;landıracaktır. Verilecek cevapta on sayfaya
                kadar &uuml;cret alınmayacaktır. On sayfanın &uuml;zerindeki her sayfa i&ccedil;in 1 T&uuml;rk Lirası
                işlem &uuml;creti alınacaktır. Başvuruya cevabın CD, flash bellek gibi bir kayıt ortamında verilmesi
                halinde şirketimiz tarafından talep edilebilecek &uuml;cret kayıt ortamının maliyetini
                ge&ccedil;meyecektir.
                <br/>
                <br/>
                Bu kapsamda kişisel veri sahipleri;
            </p>
            <ul>
                <ul>
                    <li>
                        Kişisel veri işlenip işlenmediğini &ouml;ğrenme,
                    </li>
                    <li>
                        Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                    </li>
                    <li>
                        Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
                        kullanılmadığını &ouml;ğrenme,
                    </li>
                    <li>
                        Yurt i&ccedil;inde veya yurt dışında kişisel verilerin
                        aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,
                    </li>
                    <li>
                        Kişisel verilerin eksik veya yanlış işlenmiş olması h&acirc;linde bunların d&uuml;zeltilmesini
                        isteme ve bu kapsamda yapılan işlemin kişisel verilerin
                        aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,
                    </li>
                    <li>
                        6698 sayılı Kanun ve ilgili diğer kanun h&uuml;k&uuml;mlerine uygun olarak işlenmiş olmasına
                        rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini
                        veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin
                        aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,
                    </li>
                    <li>
                        İşlenen verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
                        kişinin kendisi aleyhine bir sonucun ortaya &ccedil;ıkmasına itiraz etme,
                    </li>
                    <li>
                        Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın
                        giderilmesini talep etme haklarına sahiptir.
                    </li>
                </ul>
            </ul>
            <p>
                Yukarıda belirtilen haklarınızı kullanma ile ilgili talebinizi, 6698 sayılı Kanunu&rsquo;nun 13.
                maddesinin 1. fıkrası ve 30356 sayılı ve 10.03.2018 tarihli Veri Sorumlusuna Başvuru Usul ve Esasları
                Hakkında Tebliğ gereğince T&uuml;rk&ccedil;e ve yazılı olarak veya kayıtlı elektronik posta (KEP)
                adresi, g&uuml;venli elektronik imza, mobil imza ya da OTTO SOLMAZ&rsquo;e daha &ouml;nce bildirilen ve
                sistemimizde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle iletebilirsiniz. OTTO
                SOLMAZ&rsquo;ın cevap vermeden &ouml;nce kimliğinizi doğrulama hakkı saklıdır.
                <br/>
                <br/>
                Başvurunuzda;
            </p>
            <ul>
                <ol>
                    <li>
                        Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,
                    </li>
                    <li>
                        T&uuml;rkiye Cumhuriyeti vatandaşları i&ccedil;in T.C. kimlik numaranızın, yabancı iseniz
                        uyruğunuzun, pasaport numaranızın veya varsa kimlik numaranızın,
                    </li>
                    <li>
                        Tebligata esas yerleşim yeri veya iş yeri adresinizin,
                    </li>
                    <li>
                        Varsa bildirime esas elektronik posta adresi, telefon ve faks numaranızın,
                    </li>
                    <li>
                        Talep konunuzun, bulunması zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de başvuruya
                        eklenmesi gerekmektedir.
                    </li>
                </ol>
            </ul>
            <p>
                Posta yolu ile yapmak istediğiniz başvurularınızı, veri sorumlusu olarak Ayakkabionline.com Barbaros
                mahallesi Troya caddesi 40/42 &Ccedil;anakkale / T&uuml;rkiye adresine g&ouml;nderebilirsiniz.
                <br/>
                <br/>
                E-posta yoluyla yapmak istediğiniz başvurularınızı&nbsp;
                <a href="mailto:kvkk@ayakkabionline.com">
                    kvkk@ayakkabionline.com
                </a>
                &nbsp;e-posta adresine veya&nbsp;
                <a href="mailto:ottosolmaz@hs03.kep.tr">
                    ottosolmaz@hs03.kep.tr
                </a>
                &nbsp;Kayıtlı Elektronik Posta adresine yapabilirsiniz.
            </p>
            <p>
                &nbsp;
            </p>
        </div>

    )
}