import React from 'react';

export default function PersonnelInformation(){
    return (
        <div className="information text-left">
            <center>
            <p><strong>OTTO SOLMAZ AYAKKABI DERİ &Uuml;R. TUR. SAN. DIŞ. TİC. DNŞ. LTD. ŞTİ.</strong></p>
                </  center>
            <p><strong>Otto Solmaz Ayakkabı Deri &Uuml;r. Tur. San. Dış. Tic. Dnş. Ltd. Şti.&nbsp;</strong>(“Şirket”) hangi kişisel verilerinizi ne amaçlarla işleyeceğimizi siz çalışanlarımıza anlatabilmek amacıyla bu Aydınlatma Metnini hazırladık. Aşağıda belirttiğimiz kişisel verilerinizi her koşulda;</p>
            <ul>
            <li>Hukuka ve dürüstlük kurallarına uygun olarak,</li>
            <li>Paylaştığınız kişisel verilerin doğruluğunu ve tarafımıza bildirdiğiniz şekilde en güncel halini koruyarak,</li>
            <li>Belirli, açık ve hukuka uygun amaçlar için,</li>
            <li>İşlenecekleri amaçla bağlantılı, sınırlı ve ölçülü olacak şekilde,</li>
            <li>İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar saklayarak işleyeceğimizi,</li>
            <li>KVKK'nın öngördüğü şartlarda, yurtiçi veya yurtdışı üçüncü kişilere aktarılacağını, devredileceğini, sınıflandırılabileceğini ve KVKK' da sayılan sair şekillerde işlenebileceğini bildiririz.</li>
            </ul>

                <p><strong>İŞLENEN KİŞİSEL VERİLERİNİZ</strong></p>

            <p>Tarafımızca çalışanlara ilişkin kendileri tarafından tarafımıza sağlanan kişisel veriler işlenebilmektedir. Örnek olmak üzere işlemeye konu olabilecek kişisel verileriniz aşağıdaki gibidir:</p>

            <p><strong>KİMLİK VERİSİ</strong></p>
            <p>Ad, soyadı, doğum tarihi, doğduğu ülke, doğduğu şehir, cinsiyet, medeni durumu, T.C kimlik kartı bilgileri (TCKN, seri no, cüzdan no, baba adı, anne adı, doğum yeri, il, ilçe, mahalle, cilt no, aile sıra no, sıra no, hane no, sayfa no, kayıt no, verildiği yer, veriliş nedeni, veriliş tarihi, önceki soyadı), nüfus cüzdanı sureti, imza.</p>

            <p><strong>İLETİŞİM VERİSİ</strong></p>
            <p>Telefon numarası, açık adres bilgisi, e-posta adresi, Şirket içi iletişim bilgileri (dahili telefon numarası, kurumsal e-posta adresi)</p>

            <p><strong>FİNANSAL VERİ</strong></p>
            <p>Finansal ve maaş detayları, bordrolar, prim hak edişleri, prim tutarları, icra takip dosyalarına ilişkin dosya ve borç bilgileri, banka hesap cüzdanı, asgari geçim indirimi bilgisi, </p>

            <p><strong>ÖZEL NİTELİKLİ KİŞİSEL VERİ</strong></p>
            <p>Sabıka kaydı, engellilik durumu/tanımı/yüzdesi, dini, sağlık verisi, kan grubu, işbaşı sağlık raporu, akciğer grafisi, işitme testi, göz testi, işyeri hekiminin imzalattığı işe giriş ve periyodik muayene formları, hamilelik durumu, hamilelik raporu, sağlık ve doğum izni bilgileri, kılık kıyafet bilgisi, Biyometrik veri (Personel takip programı aracılığı ile) (Sağlık raporu tıbbi detaylar işyeri hekimi tarafından muhafaza edilmektedir.)</p>

            <p><strong>EĞİTİM VERİSİ</strong></p>
            <p>Öğrenim durumu, sertifika ve diploma bilgileri, yabancı dil bilgileri, eğitim ve beceriler, CV, aldığı kurslar,</p>

            <p><strong>FİZİKSEL MEKAN GÜVENLİĞİ VERİSİ</strong></p>
            <p>Çalışanların giriş çıkış kayıt bilgileri, Kamera kayıtları vb.</p>


        </div>
    )
}