import React from 'react';

export default function PersonnelCandidateInformation(){
    return (
        <div className="information text-left">
            <center>
                <p><strong>OTTO SOLMAZ AYAKKABI DERİ &Uuml;R. TUR. SAN. DIŞ. TİC. DNŞ. LTD. ŞTİ.</strong></p>
                <p><strong>6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU UYARINCA
                KİŞİSEL VERİLERİNİZİN KORUNMASI HAKKINDA ÇALIŞAN ADAYI AYDINLATMA METNİ</strong></p>
            </center>

            <p><strong>Otto Solmaz Ayakkabı Deri &Uuml;r. Tur. San. Dış. Tic. Dnş. Ltd. Şti.&nbsp;</strong>(“Şirket”) bünyesindeki kişisel veriler, veri sorumlusu olan Şirket’in koruması altındadır. Şirket, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) çerçevesinde kişisel verilerin hukuka uygun olarak işlenmesini ve güvenli şekilde muhafazasını sağlamak için teknolojik ve alt yapısal imkanlarını kullanarak, gerekli teknik ve idari tedbirleri almaktadır.</p>

            <p><strong>KİŞİSEL VERİLERİNİZİ NE ŞEKİLDE VE HANGİ HUKUKİ SEBEPLERLE TOPLUYORUZ</strong></p>
            <p>Şirket ile aranızda kurulması hedeflenen hukuki ilişki çerçevesinde iş sözleşmesinin kurulması için gerekli olması nedeniyle sizden talep ettiğimiz kişisel verilerinizi, sizin bize sözlü, fiziksel veya elektronik ortamda herhangi bir formda iletmeniz aracılığıyla açık rızanız ile topluyoruz.</p>

            <p>Kariyer portalları aracılığıyla paylaştığınız kişisel verilerinizi bize bu platformlar üzerinden iş başvurusu yapmanız yoluyla veya platformlar üzerinde yayınladığınız bilgileri 3. kişilerle paylaşma tercihlerinize göre bu platformlar içerisinden iş sözleşmesinin kurulması için gerekli olması nedeniyle topluyoruz</p>

            <p><strong>KİŞİSEL VERİLERİNİZİ İŞLEME AMAÇLARIMIZ</strong></p>
            <p>Sizin; ad, soyadı, iletişim verisi, eğitim verisi, iş deneyimi verisi, referans gösterilen kişi verisi, kimlik verisi, finansal veri, aile ve yakın verisi ve rızanız olması halinde video görüntüsü, dernek, vakıf ya da sendika üyeliği, sağlık verisi, sabıka kaydı, kimlik, ehliyet fotokopisi, fotoğraf, askerlikten muafiyet nedeninden oluşan kişisel verilerinizi;</p>
            <ul>
                <li>İşe alım için özgeçmiş taraması yapılması,</li>
                <li>Başvuran adayların görüşmeye çağrılması,</li>
                <li>Başvuran adayların değerlendirilmesi,</li>
                <li>Aday önerilmesi,</li>
                <li>Adaylarla iletişime geçilmesi,</li>
                <li>Referans kişileriyle iletişime geçilmesi, amaçlarıyla işlemekteyiz.</li>
            </ul>
            <p>Mevzuat kapsamında yasal yükümlülüklerimizi yerine getirebilmek ve savunma hakkımızı kullanabilmek adına yalnızca gerekli olan kişisel verilerinizi işliyoruz.</p>

            <p>Kişisel verilerinizi yalnızca ilgili mevzuatta belirtilen veya işlendikleri amaç için gerekli olan azami süre ve herhalde kanuni zamanaşımı süreleri kadar muhafaza edeceğimizi temin ediyoruz.</p>

            <p><strong>KİŞİSEL VERİLERİNİZİN ÜÇÜNCÜ KİŞİLERE AKTARIMI</strong></p>
            <p>Kişisel Verileriniz 3. Kişilere aktarılmamaktadır.</p>

            <p><strong>KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA HAKLARINIZ</strong></p>
            <p>Kanun’un 11. maddesi uyarınca kişisel verileriniz ile ilgili;</p>
            <ul>
                <li>Şirket’in hakkınızda kişisel veri işleyip işlemediğini öğrenmek, eğer işlemişse, buna ilişkin bilgi talep etmek,</li>
                <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığı öğrenmek,</li>
                <li>Kişisel verilerin yurtiçi veya yurtdışına aktarılıp aktarılmadığı ve kimlere aktarıldığını öğrenmek,</li>
                <li>Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini istemek,</li>
                <p>haklarına sahipsiniz.</p>
            </ul>

            <p>Kişisel verilerinizin Kanun’da öngörülen şartlar çerçevesinde silinmesini, yok edilmesini veya anonim hale getirilmesini talep edebilirsiniz. Talebinizi alarak somut duruma göre en uygun imha yönetimini seçeceğiz, bu konuda bizden her zaman bilgi talep edebilirsiniz.</p>

            <p>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep edebilirsiniz.</p>

            <p>Başvurunuzda yer alan taleplerinizi, talebin niteliğine göre en geç otuz (30) gün içinde ücret almadan sonuçlandırmaya çalışacağız ancak, işlemin Şirket için ayrıca bir maliyet gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu (“Kurul”) tarafından belirlenen tarifedeki ücreti sizden talep edebiliriz.  Kurul’un belirlediği ücret tarifesine göre; başvurunuza yazılı olarak cevap vermemiz halinde on sayfaya kadar sizden ücret almayacağız ancak on sayfanın üzerindeki her sayfa için 1 Türk Lirası işlem ücreti alabiliriz. Başvuruya cevabı CD, flash bellek gibi bir kayıt ortamında vermemiz halinde kayıt ortamının maliyeti kadar bir ücret talep edebiliriz.</p>

            <p>Kişisel verilerinizin işlenmesi ile ilgili hususlarda başvurunuzu isterseniz Şirkette kayıtlı olan e-posta adresiniz ile<span>&nbsp;</span><a href="mailto:kvkk@ottosolmaz.com">kvkk@ottosolmaz.com</a><span>&nbsp;</span>adresine e-posta göndererek veya Şirketin internet sitesinde bulunan başvuru formunu doldurup imzalamak ve şahsen kimliğinizi ispatlamak suretiyle Şirketimize teslim etmeniz gerekmektedir. Hatırlatmak isteriz ki, Şirket tarafından başvurunun size ait olup olmadığının belirlenmesi ve böylece haklarınızı koruyabilmek amacıyla ek doğrulamalar istenebilir. Örneğin Şirkette kayıtlı olan e-posta adresiniz aracılığıyla başvuru yapmanız halinde Şirkette kayıtlı başka bir iletişim yöntemini kullanarak size ulaşabilir ve başvurunun size ait olup olmadığının teyidini isteyebiliriz.</p>

            <p><strong>Açık Rıza Beyan Formu</strong></p>

            <p>6698 sayılı Kişisel Verilerin Korunması Kanunu gereğince, kişisel verilerimin <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti.</strong> Tarafından hangi amaçla işleneceği, işlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği, kişisel veri toplamanın yöntemi ve hukuki sebebi ve Kanun’un 11. maddesinde sayılan diğer haklarım hususlarında bilgilendirildim.</p>

            <p>Başvurduğum pozisyona ilişkin uygunluğumun değerlendirilmesi, <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti.</strong> İnsan kaynakları politikaları çerçevesinde işe alım süreçlerinin yürütülmesi, sonuçlandırılması, İnsan kaynakları yetkilisi tarafından alınan notlar da dahil olmak üzere <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti.</strong>’ne ilettiğim her türlü kişisel verimin, <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti.</strong> Tarafından kaydedilmesine, depolanmasına, değiştirilmesine, güncellenmesine, periyodik olarak kontrol edilmesine, yeniden düzenlenmesine, sınıflandırılmasına, özgeçmişimde yer alan ve kendi irademle seçtiğim referanslarımın ve/veya <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti.</strong> Tarafından bizzat ulaşılan diğer referansların <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti.</strong> İnsan kaynakları departmanı tarafından aranarak ilgili kişilerden işe yatkınlığım hakkında bilgi/veri alınmasına ve bu bilgi/verilerin <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti.</strong> Tarafından kaydedilmesine, kişisel verilerimin <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti</strong>’nin yasal yükümlülüklerini yerine getirmesi amacıyla yetkili mercilerle paylaşılmasına, yukarıda bahsi geçen kişisel verilerimin <strong>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış. Tic. Dnş. Ltd. Şti.</strong> İnsan kaynakları havuzunda muhafaza edilmesine,</p>

            <p>Onay veriyorum</p>                   <p>Onay vermiyorum</p>
            <p>Adayın Adı-Soyadı:</p>               <p>İmza</p>                     <p>Tarih:</p>
        </div>
    )
}