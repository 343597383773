import React from "react";
import { Link } from "react-router-dom";

export default function Information() {
    return (
        <div className="information">
            <h5>Bilgilendirme Sayfaları</h5>
            <ul className="list-group">
                <li className="list-group-item">
                    <Link to="/information/customer-information" className="d-block">Müşteri Aydınlatma Metni</Link>
                </li>
                <li className="list-group-item">
                    <Link to="/information/electronic-commercial" className="d-block">Elektronik Ticari İleti Açık Rıza
                        Metni</Link>
                </li>
                <li className="list-group-item">
                    <Link to="/information/supplier-information" className="d-block">Tedarikçi Aydınlatma Metni</Link>
                </li>
                <li className="list-group-item">
                    <Link to="/information/personnel-information" className="d-block">Çalışan Aydınlatma Metni</Link>
                </li>
                <li className="list-group-item">
                    <Link to="/information/personnel-candidate-information" className="d-block">Çalışan Adayı Aydınlatma
                        Metni</Link>
                </li>
                <li className="list-group-item">
                    <Link to="/information/kvkk" className="d-block">KVKK Aydınlatma Metni</Link>
                </li>
            </ul>
        </div>
    )
}