import React from 'react';

export default function SupplierInformation(){
    return (
        <div className="information text-left">
            <p><strong>OTTO SOLMAZ AYAKKABI DERİ &Uuml;R. TUR. SAN. DIŞ. TİC. DNŞ. LTD. ŞTİ.</strong></p>
            <p><strong>TEDARİK&Ccedil;İ VE TEDARİK&Ccedil;İ &Ccedil;ALIŞANLARINA AİT KİŞİSEL VERİLERİNİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ</strong></p>
            <p><strong>Otto Solmaz Ayakkabı Deri &Uuml;r. Tur. San. Dış. Tic. Dnş. Ltd. Şti.&nbsp;</strong>(Şirket) ile paylaştığınız kişisel verileriniz veri sorumlusu sıfatıyla hassasiyetle korunmaktadır. Bu bağlamda 6698 sayılı Kişisel Verilerin Korunması Kanunu (bundan b&ouml;yle "KVKK&rdquo; olarak anılacaktır) M.10 kapsamında aydınlatma y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirmek amacıyla tedarik&ccedil;i ve tedarik&ccedil;i &ccedil;alışanlarının KVKK&rsquo;dan kaynaklanan hakları ve uygulamaları dikkatinize sunulmaktadır. Şirket, Aydınlatma Metninde gerekli olduğu takdirde her zaman değişiklik yapabilir. Yapılacak değişiklikler, Aydınlatma Metninin internet sitesinde yayınlanmasıyla birlikte derhal ge&ccedil;erlilik kazanır.</p>
            <p><strong>1.İşlenen kişisel verileriniz</strong></p>
            <ul>
            <li>Kimlik Verisi; Ad, Soyad, T.C. Kimlik Numarası</li>
            <li>İletişim Verisi; Telefon Numarası, &Ccedil;alıştığı Adres, E-Posta Adresi,</li>
            <li>Finans: Vergi Numarası, Iban Numarası, Hesap Numarası,</li>
            <li>Fiziksel mek&acirc;n g&uuml;venliği; kamera g&ouml;r&uuml;nt&uuml;leri (Şirket binasında g&ouml;r&uuml;şmeniz olması halinde)</li>
            <li>İşlem G&uuml;venliği</li>
            <li>&Ouml;zl&uuml;k Verisi; &Ccedil;alıştığı Firma Adı, Unvanı,</li>
            </ul>
            <p>&nbsp;verilerini ifade etmektedir.</p>
            <p>Aydınlatma metninin devamında verilerinizin hangi ama&ccedil;la işlendiği, işlenen kişisel verilerin kimlere ve hangi ama&ccedil;la aktarılabileceği, kişisel veri toplamanın y&ouml;ntemi, hukuki sebebi ve haklarınızı belirtilmektedir.</p>
            <p>Kişisel verileriniz fiziksel ve dijital ortamlarda saklanmaktadır. Şirket tarafından kişisel verilerinizin g&uuml;venliği i&ccedil;in idari ve teknik her t&uuml;rl&uuml; g&uuml;venlik &ouml;nlemi alınmaktadır.</p>
            <p><strong>2.Kişisel verilerinizin işlenme ama&ccedil;ları</strong></p>
            <p>Şirket tarafından kişisel verileriniz aşağıda belirtilen ama&ccedil; ve hukuki sebepler gibi ancak bunlarla sınırlı olmayan ve gerektiği takdirde benzer ama&ccedil; ve sebeplerle KVKK m. 5 ve m.6&rsquo;da belirtilen şartlar dahilinde işlenmektedir.</p>
            <p>&nbsp;Kişisel verilerinizin işleme ama&ccedil;ları aşağıdaki gibidir;</p>
            <ul>
            <li>Finans ve muhasebe işlerinin y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>Hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>İş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve denetimi</li>
            <li>Bilgi g&uuml;venliği s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi</li>
            <li>Eğitim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>İletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>Tedarik zinciri y&ouml;netim s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>Talep ve şikayetlerin takibi</li>
            <li>Mal ve hizmet satın alım s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>Mal ve hizmet &uuml;retim ve operasyon s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi</li>
            <li>Şirket ziyaret kayıtlarının oluşturulması ve takibi</li>
            </ul>
            <ol start="3">
            <li><strong> Kişisel Verilerinizi Toplama Y&ouml;ntemleri</strong></li>
            </ol>
            <p>Kişisel verileriniz elektronik ortam aracılığıyla, sizin tarafınızdan sağlanan t&uuml;m bilgi ve belgeler, s&ouml;zl&uuml; olarak, &ccedil;alıştığınız firmanın s&ouml;zl&uuml; veya yazılı olarak Şirket ile paylaştığı e-posta aracılığıyla, tarafınızca verilen kartvizit, doldurulan bilgi formu ve mutabakat formu aracılığıyla elde edilmektedir. Ayrıca işyeri g&uuml;venliği sağlamaya y&ouml;nelik hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; ve meşru menfaati gereği, işyeri binasına yerleştirdiğimiz kameralar aracılığıyla, yetkili kurum ve kuruluşlardan şahsınıza ait hukuki belge ve tebligatların Şirket&rsquo;e iletilmesiyle toplanmaktadır.</p>
            <ol start="4">
            <li><strong> Kişisel Verileriniz Hangi Ama&ccedil;la Kimlere Aktarılmaktadır?</strong></li>
            </ol>
            <p>Şirket elde ettiği kişisel verileri a&ccedil;ık rızaya istinaden ve KVKK&rsquo;da Toplanan kişisel verileriniz, Kanun tarafından &ouml;ng&ouml;r&uuml;len temel ilkelere uygun olarak ve Kanun&rsquo;un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve ama&ccedil;ları dahilinde ayrıca aşağıda belirtilen ama&ccedil;lara istinaden g&uuml;venlik ve gizlilik esasları &ccedil;er&ccedil;evesinde ve belirtilen ama&ccedil;la sınırlı olan kişisel verileriniz yeterli &ouml;nlemleri almak kaydıyla;</p>
            <ul>
            <li>Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesi ve mevzuattan kaynaklanan y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi amacıyla yetkili kamu kurum ve kuruluşlarıyla,</li>
            <li>Hukuki s&uuml;re&ccedil;lerin takibi bakımından danışmanlık hizmeti alınan şirket avukatlarıyla,</li>
            <li>Lojistik faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi amacıyla kargo firmalarıyla</li>
            </ul>
            <p>&nbsp;paylaşılmaktadır.</p>
            <ol start="5">
            <li><strong> Kişisel Verilerinizin İşlenmesinin Hukuki Sebebi ve Muhafaza Edildiği S&uuml;re</strong></li>
            </ol>
            <p>Kişisel Verilerin Korunması Kanunu, T&uuml;rk Ticaret Kanunu, Vergi Usul Kanunu ve ilgili diğer kanunlar ve mevzuat kapsamında belirtilen veya işlendikleri ama&ccedil; i&ccedil;in gerekli olan azami s&uuml;re ve herhalde kanuni zamanaşımı s&uuml;releri kadar muhafaza edilecektir.</p>
            <p>Kişisel verilerinizin ne kadar s&uuml;re boyunca saklanması gerektiğine ilişkin mevzuatta bir s&uuml;re d&uuml;zenlenmemişse, Şirket bahsi ge&ccedil;en kişisel veriyi işlerken sunduğu hizmetlerle bağlı olarak Şirketin uygulamaları ve ticari yaşamının team&uuml;lleri uyarınca islenmesini gerektiren s&uuml;re kadar işlemektedir.</p>
            <ol start="6">
            <li><strong> KVKK M. 11 Kapsamında Haklarınız</strong></li>
            </ol>
            <p>KVKK 11. madde kapsamında, kişisel veri sahibi olarak,</p>
            <ul>
            <li>Kişisel verilerinizin işlenip işlenmediğini &ouml;ğrenme,</li>
            <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>Kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme,</li>
            <li>Yurt i&ccedil;inde veya yurt dışında kişisel verilerinizin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,</li>
            <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması h&acirc;linde bunların d&uuml;zeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</li>
            <li>6698 sayılı Kanun ve ilgili diğer kanun h&uuml;k&uuml;mlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması h&acirc;linde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</li>
            <li>İşlenen verilerinizin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya &ccedil;ıkmasına itiraz etme,</li>
            <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız h&acirc;linde zararın giderilmesini talep etme</li>
            </ul>
            <p>hakkınız bulunmaktadır.</p>
            <p>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, yazılı veya Şirket tarafından hazırlanan bilgi talebi başvuru formunu doldurarak iletebilirsiniz. Başvuru formunda tercih ettiğiniz y&ouml;ntemi belirtmeniz gerekmektedir. Talebin niteliğine g&ouml;re en ge&ccedil; otuz g&uuml;n i&ccedil;inde talebiniz yerine getirilecektir. Ancak işlemin ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye g&ouml;re tarafınızdan &uuml;cret talep edilebilecektir.</p>
            <p>&nbsp;</p>
            <p><strong><u>VERİ SORUMLUSU İLETİŞİM BİLGİLERİ</u></strong></p>
            <p><strong>OTTO SOLMAZ AYAKKABI DERİ &Uuml;R. TUR. SAN. DIŞ TİC. DNŞ. LTD. ŞTİ.</strong></p>
            <p><strong><u>ADRES:</u></strong><strong> Barbaros Mahallesi Troya Caddesi No: 40-42 Merkez/&Ccedil;ANAKKALE</strong></p>
            <p><strong><u>MERSİS NO:</u></strong><strong> 0649430026000027</strong></p>
            <p><strong><u>E-POSTA:</u></strong> <span><a href="mailto:kvkk@ottosolmaz.com"><strong>kvkk@ottosolmaz.com</strong></a></span></p>
            <p><strong><u>KEP ADRESİ:</u></strong> <span><strong><a href="mailto:ottosolmaz@hs03.kep.tr">ottosolmaz@hs03.kep.tr</a><br /><br /><br /><br /><a target="_blank" href="http://img.ayakkabionline.com/site/files/veri-sahibi-basvuru-formu.pdf" title="Veri Sahibi Başvuru Formu">Veri Sahibi Başvuru Formuna Ulaşmak İ&ccedil;in Tıklayın</a><br /></strong></span></p>
        </div>
    )
}