import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Menu from './components/Menu';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
// import Documents from './pages/Documents';
import Information from './pages/Information';
import CustomerInformation from './pages/Information/CustomerInformation';
import ElectronicCommercial from './pages/Information/ElectronicCommercial';
import SupplierInformation from './pages/Information/SupplierInformation';
import PersonnelInformation from './pages/Information/PersonnelInformation';
import PersonnelCandidateInformation from './pages/Information/PersonnelCandidateInformation';
import NoContent from './pages/NoContent';
import NotFound from './pages/NotFound';
import logo from './assets/img/logo-solo.png';
import KvkkInformation from "./pages/Information/KvkkInformation";

export default function App() {
  return (
    <Router>
      <div id="logo">
        <Link to="/">
          <img src={logo} />
        </Link>
      </div>
      <Menu />
      <div className="container content">
        <Switch>
          <Route path="/information/supplier-information">
            <SupplierInformation />
          </Route>
          <Route path="/information/electronic-commercial">
            <ElectronicCommercial />
          </Route>
          <Route path="/information/customer-information">
            <CustomerInformation />
          </Route>
          <Route path="/information/personnel-information">
            <PersonnelInformation />
          </Route>
          <Route path="/information/personnel-candidate-information">
            <PersonnelCandidateInformation />
          </Route>
          <Route path="/information/kvkk">
            <KvkkInformation />
          </Route>
          <Route path="/information">
            <Information />
          </Route>
          <Route path="/documents">
            <NoContent />
            {/* <Documents /> */}
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}