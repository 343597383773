import React from 'react';
import { Link } from "react-router-dom";

export default function Menu(){
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                Menü &nbsp;
                <span class="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">
                            Ana Sayfa
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">
                            Şirket Bilgileri
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/documents">
                            Belgeler
                        </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="/information">
                            Bilgilendirme
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}