import React from 'react';

export default function CustomerInformation(){
    return (
        <div className="information text-left">
            <p><strong><span>OTTO SOLMAZ AYAKKABI DERİ &Uuml;R. TUR. SAN. DIŞ. TİC. DNŞ. LTD. ŞTİ.</span></strong></p>
            <p><strong><span>&Uuml;R&Uuml;N VE HİZMET ALAN KİŞİ/M&Uuml;ŞTERİ/POTANSİYEL &Uuml;R&Uuml;N VEYA HİZMET ALICISINA AİT KİŞİSEL VERİLERİNİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ</span></strong></p>
            <p><span>Kişisel verilerin korunması Otto Solmaz Ayakkabı Deri &Uuml;R.TUR.SAN.DIŞ TİC.DNŞ.LTD.ŞTİ. (&ldquo;OTTO SOLMAZ&rdquo;) i&ccedil;in &ouml;nemli bir konudur. OTTO SOLMAZ, 6698 Sayılı Kişisel Verilerin Korunması Kanunu&rsquo;na (&ldquo;KVK Kanunu&rdquo;) uyum sağlanması i&ccedil;in KVK Kanunu&rsquo;nun &ouml;ng&ouml;rd&uuml;ğ&uuml; ilkeleri benimsemekte, kişisel verilerin işlenmesi, silinmesi, yok edilmesi, anonim hale getirilmesi, aktarılması, ilgili kişinin aydınlatılması ve veri g&uuml;venliğinin sağlanmasıyla ilgili y&uuml;k&uuml;ml&uuml;l&uuml;klerini yerine getirmektedir. Bu kapsamda d&uuml;zenlenen Gizlilik ve Kişisel Verilerin Korunması Politikası kişisel verisi işlenen ger&ccedil;ek kişilerin (&ldquo;İlgili Kişi&rdquo;) erişimine sunulmaktadır.</span></p>
            <p><span>Amacımız; 6698 sayılı KVK Kanunu&rsquo;nun 10. maddesi gereğince ve sizlerin memnuniyeti doğrultusunda, kişisel verilerinizin alınma şekilleri, işlenme ama&ccedil;ları, paylaşılan kişiler, hukuki nedenleri ve haklarınız konularında sizi en şeffaf şekilde bilgilendirmektir.</span></p>
            <p><strong><span>Veri Konusu Kişi Grubu Kategorizasyonu</span></strong></p>
            <p><span>OTTO SOLMAZ kişisel veri işleme s&uuml;re&ccedil;lerinde ve bu s&uuml;re&ccedil;lere bağlı faaliyetlerde kişisel verileri işlenen veri konusu kişi gruplarını kategorize etmektedir. Bununla beraber KVK Kanunu&rsquo;nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartlarına uygun olarak ve işbu Gizlilik/Kişisel Verilerin Korunması Politikası&rsquo;nda belirtilen hukuki sebepler doğrultusunda sair kişi gruplarının da (danışman, eğitimci, blogger) kişisel verileri işlenebilmektedir.</span></p>
            <p><strong><span>M&uuml;şteri/&Uuml;ye, Potansiyel M&uuml;şteri/&Uuml;ye</span></strong></p>
            <p><span>Kimlik Bilgisi: Ad, soyadı, doğum tarihi, cinsiyet, T.C. kimlik numarası</span></p>
            <p><span>Lokasyon Bilgisi: Yaşadığı şehir, il&ccedil;e (ottosolmaz.com aracılığıyla yapılan alışverişin teslimat adresi)</span></p>
            <p><span>İletişim Bilgisi: cep telefonu, e-posta adresi, adres, posta kodu, sabit telefon</span></p>
            <p><span>Finansal Bilgi: Vergi dairesi, fatura bilgileri</span></p>
            <p><span>M&uuml;şteri/&Uuml;ye Bilgisi: &Uuml;yelik bilgisi, &uuml;yelik ID numarası</span></p>
            <p><span>M&uuml;şteri/&Uuml;ye İşlem Bilgisi: Satın alınan &uuml;r&uuml;n/ler, alışveriş tutarı, alışveriş tarihi, &ccedil;ağrı merkezi g&ouml;r&uuml;şme kayıtları, ticari iletişim izni, faydalanılan kampanyalar, kullanılan kuponlar, siparişe ilişkin bilgiler</span></p>
            <p><span>Risk Y&ouml;netimi Bilgisi: IP adresi</span></p>
            <p><span>İşlem G&uuml;venliği Bilgisi: Şifre, parola bilgileri</span></p>
            <p><span>Pazarlama Bilgisi: &Ccedil;erez kayıtları, hedefleme bilgileri, alışkanlık ve beğenileri g&ouml;steren değerlendirmeler</span></p>
            <p><span>İşitsel Veri: &Ccedil;ağrı merkezi g&ouml;r&uuml;şme kayıtları</span></p>
            <p><span>Hukuki İşlem ve Uyum Bilgisi: Verilen hizmetin başlama ve bitiş zamanı, yararlanılan hizmetin t&uuml;r&uuml;, aktarılan veri miktarı, İlgili Kişi&rsquo;nin elektronik ortamda verdiği ticari elektronik ileti izni, onay verdiği &uuml;yelik s&ouml;zleşmesi, kurumsal &uuml;yelik s&ouml;zleşmesi, mesafeli satış s&ouml;zleşmesi ve OTTO SOLMAZ tarafından sunulan hizmetlerden faydalanılmasını sağlayan sair hukuki metinler ve s&ouml;zleşmeler</span></p>
            <p><span>Pazarlama Bilgisi: İlgili kişinin verdiği ticari elektronik ileti iznine istinaden g&ouml;nderilen pazarlama ama&ccedil;lı sms, e-posta mesajları veya &ccedil;ağrı merkezi tarafından yapılan aramalar</span></p>
            <p><span>Talep/Şikayet Y&ouml;netimi/İtibar Y&ouml;netimi Bilgisi: İlgili kişinin satın almış olduğu &uuml;r&uuml;n veya hizmete ilişkin web sitesi, mobil aplikasyon, sosyal medya hesapları veya &ccedil;ağrı merkezi &uuml;zerinden ilettiği şikayet ve/veya talepler ile bu istemlerin değerlendirilmesi veya y&ouml;netimi s&uuml;recinde yapılan işlemlere ilişkin kayıtlar</span></p>
            <p><strong><span>&Ccedil;evrimi&ccedil;i Ziyaret&ccedil;i</span></strong></p>
            <p><span>İşlem G&uuml;venliği Bilgisi: Şifre, cep telefonu, parola bilgileri</span></p>
            <p><span>Hukuki İşlem Bilgisi/Risk Y&ouml;netimi Bilgisi: IP adresi</span></p>
            <p><span>Hukuki İşlem ve Uyum Bilgisi: Verilen hizmetin başlama ve bitiş zamanı, yararlanılan hizmetin t&uuml;r&uuml;, aktarılan veri miktarı.</span></p>
            <p><strong><span>Satın Alınan &Uuml;r&uuml;n&uuml;n Adına Teslim Edileceği Kişi</span></strong></p>
            <p><span>Kimlik Bilgisi: Ad, soyadı, doğum tarihi, cinsiyet, T.C. kimlik numarası</span></p>
            <p><span>Lokasyon Bilgisi: Yaşadığı şehir, il&ccedil;e (ottosolmaz.com aracılığıyla yapılan alışverişin teslimat adresi)</span></p>
            <p><span>İletişim Bilgisi: cep telefonu, e-posta adresi, adres, posta kodu, sabit telefon</span></p>
            <p><span>Finansal Bilgi: Vergi dairesi, fatura bilgileri</span></p>
            <p><span>Kişisel Verilerin Hangi Ama&ccedil;la İşleneceği</span></p>
            <p><span>OTTO SOLMAZ tarafından, m&uuml;şterileri, &ccedil;alışanları, potansiyel m&uuml;şterileri, &ccedil;alışan adayları, iş ortakları ve tedarik&ccedil;ileri gibi taraflardan, kimlik bilgisi, iletişim bilgisi, m&uuml;şteri bilgisi, m&uuml;şteri işlem bilgisi, işlem g&uuml;venliği bilgisi, hukuki işlem ve uyum bilgisi ile pazarlama satış bilgisi gibi kategorilerde kişisel veri toplanabilmektedir.</span></p>
            <p><strong><span>Toplanan Kişisel Verilerinizin İşlenme Amacı;</span></strong></p>
            <p><span>OTTO SOLMAZ &uuml;r&uuml;n ve hizmetlerinin sizlere sunulabilmesi, sizlere karşı olan y&uuml;k&uuml;ml&uuml;l&uuml;klerimizin yerine getirilmesi, kayıt ve belgelerin d&uuml;zenlenebilmesi, yerel ve uluslararası yasal mevzuatın &ouml;ng&ouml;rd&uuml;ğ&uuml; bilgi saklama, raporlama, bilgilendirme, vergi ve sair y&uuml;k&uuml;ml&uuml;l&uuml;klere uymak,</span></p>
            <p><span>Hizmet ve &uuml;r&uuml;nlerin kalitesinin artırılmasına y&ouml;nelik yapılacak olan satış ve pazarlama faaliyetleri i&ccedil;in yapılacak size &ouml;zel reklam, kampanya, avantajlar ve diğer faydaları sunmak,</span></p>
            <p><span>Bilgi işlem gereksinimleri, sistemsel yapısı, alınan bilgi işlem destek hizmetlerinin gerekliliği, bu hizmet ve &uuml;r&uuml;nlere ilişkin olarak sizlere gerekli bilgilerin aktarılması amacıyla iletişim kurmak,</span></p>
            <p><span>Satış ve pazarlama faaliyetleri i&ccedil;in yapılacak trafik &ouml;l&ccedil;&uuml;mleme, istatistiki analizler, Segmentasyon/profilleme ve CRM (M&uuml;şteri ilişkileri y&ouml;netimi) &ccedil;alışmalarını y&uuml;r&uuml;tmek,</span></p>
            <p><span>M&uuml;şteri memnuniyetinin &ouml;l&ccedil;&uuml;mlenmesi ve artırılması, şik&acirc;yet y&ouml;netimi, yeni hizmet ve &uuml;r&uuml;nler ile ilgili g&ouml;r&uuml;ş ve &ouml;nerilerinizi almak, sorun-hata bildirimlerinizi almak, &uuml;r&uuml;n ve hizmetlere, şikayet ve taleplerinize y&ouml;nelik tarafınıza bilgi vermek,</span></p>
            <p><span>Online satış ile ilgili &uuml;yeliğinizi y&ouml;netmek, siparişlerinizi almak, &ouml;deme işlemlerinizi ger&ccedil;ekleştirmek, 3. kişiler ile lojistik iş birliği sağlayıp &uuml;r&uuml;n g&ouml;nderimini sağlamak, ilginizi &ccedil;ekebilecek &uuml;r&uuml;n ve hizmetleri &ouml;nermek, online davranışsal reklamcılık ve pazarlama, m&uuml;şteri portf&ouml;y y&ouml;netimi, hizmet kalitesinin &ouml;l&ccedil;&uuml;lmesi ve geliştirilmesi, iletişim, optimizasyon, denetim, risk y&ouml;netimi ve kontrol, promosyon, analiz, ilgi alanları belirleme, skorlama, profilleme, pazarlama, satış, reklam s&uuml;re&ccedil;lerini y&uuml;r&uuml;tmek,</span></p>
            <p><span>Karşılaştırmalı &uuml;r&uuml;n ve/veya hizmet teklifi, modelleme, mevcut veya yeni &uuml;r&uuml;n &ccedil;alışmaları ve/veya geliştirmeleri, kişisel verilerinizi OTTO SOLMAZ&rsquo;a a&ccedil;ıklamanıza konu olan OTTO SOLMAZ ana s&ouml;zleşmesinde yazılı olan işleri d&uuml;zenleyen kanun ve ilgili mevzuat kapsamında sizlere sunulacak her t&uuml;rl&uuml; &uuml;r&uuml;n ve hizmetlerde kullanılmak,</span></p>
            <p><span>Resm&icirc; kurumlarca &ouml;ng&ouml;r&uuml;len bilgi saklama, raporlama, bilgilendirme y&uuml;k&uuml;ml&uuml;l&uuml;klerine uymak, s&ouml;zleşmelerin gerekliliklerini yerine getirmek ve bu hizmetlerden faydalanılmasına ilişkin olarak OTTO SOLMAZ&rsquo;ın tabi olduğu yasal y&uuml;k&uuml;ml&uuml;l&uuml;kleri ifa etmek,</span></p>
            <p><span>OTTO SOLMAZ&rsquo;ın ticari ve iş stratejilerinin belirlenmesi ve uygulanması amacı doğrultusunda; OTTO SOLMAZ tarafından y&uuml;r&uuml;t&uuml;len finans operasyonları, iletişim, pazar araştırması ve sosyal sorumluluk aktiviteleri, satın alma operasyonları (talep, teklif, değerlendirme, sipariş, b&uuml;t&ccedil;elendirme, s&ouml;zleşme), şirket i&ccedil;i sistem ve uygulama y&ouml;netimi operasyonları, hukuki operasyonları y&ouml;netmek</span></p>
            <p><span>Resmi makamlardan veya sizlerden gelen talepleri incelemek, değerlendirmek ve yanıtlamak, ama&ccedil;larıyla 6698 sayılı Kanun&rsquo;un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve ama&ccedil;ları dahilinde işlenecektir.</span></p>
            <p><strong><span>İşlenen Kişisel Verilerin Kimlere ve Hangi Ama&ccedil;la Aktarılabileceği</span></strong></p>
            <p><span>Toplanan kişisel verileriniz; yukarıda belirtilen ama&ccedil;ların ger&ccedil;ekleştirilmesi ile sınırlı olmak &uuml;zere;</span></p>
            <p><span>OTTO SOLMAZ&rsquo;ın iş ortaklarına, hissedarlarına, iştiraklerine,</span></p>
            <p><span>T&uuml;rk Ticaret Kanunu, Bor&ccedil;lar Kanunu ve diğer mevzuat h&uuml;k&uuml;mlerinin izin verdiği kişi veya kuruluşlara,</span></p>
            <p><span>Kanunen yetkili kamu kurum ve kuruluşları, idari merciler ve yasal mercilere,</span></p>
            <p><strong><span>Yurtdışı şirketlerine ve iştiraklerine,</span></strong></p>
            <p><span>&Uuml;r&uuml;n/hizmet karşılaştırma, analiz, değerlendirme, reklam ve yukarıda belirtilen ama&ccedil;ların ger&ccedil;ekleştirilmesinde hizmet aldığımız, işbirliği yaptığımız ger&ccedil;ek veya t&uuml;zel kişilere, program ortağı kurum ve kuruluşlara, m&uuml;şterilerimize g&ouml;nderdiğimiz iletilerin g&ouml;nderilmesi konusunda anlaşmalı olduğumuz kurumlara, online mağazamızdan verilen siparişlerin size teslimini ger&ccedil;ekleştiren kargo şirketlerine 6698 sayılı Kanun&rsquo;un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve ama&ccedil;ları &ccedil;er&ccedil;evesinde aktarılabilecektir.</span></p>
            <p><strong><span>Kişisel Verileri Toplama Y&ouml;ntemleri ve Hukuki Sebepleri</span></strong></p>
            <p><span>OTTO SOLMAZ kişisel verileri Web siteleri, Web sitelerinin mobil uygulamaları, sosyal medya hesapları, &ccedil;erezler, &ccedil;ağrı merkezi, idari ve adli makamlardan gelen tebligatlar ve sair iletişim kanalları aracılığıyla işitsel, elektronik veya yazılı olarak veya ilerde kurulacak/oluşabilecek diğer kanallar başta olmak &uuml;zere; </span></p>
            <p><span>Profilleme ve Segmentasyon</span></p>
            <p><span>OTTO SOLMAZ M&uuml;şteri/&Uuml;ye&rsquo;ye ilişkin olarak işlediği kişisel verileri kullanarak;</span></p>
            <p><span>Ticari elektronik ileti alma konusunda onay veren M&uuml;şteri/&Uuml;ye&rsquo;ye ilişkin olarak, M&uuml;şteri/&Uuml;ye&rsquo;nin beğeni ve tercihlerine daha uygun i&ccedil;erik hazırlanması, reklam, tanıtım, indirim yapılabilmesi amacıyla profilleme ve segmentasyon yapmaktadır.</span></p>
            <p><span>Ticari elektronik ileti onayı vermemiş olan M&uuml;şteri/&Uuml;ye bakımından da profilleme ve segmentasyon yapılarak;</span></p>
            <p><span>&Uuml;r&uuml;n iyileştirmesi yapılması (en &ccedil;ok satılan veya satılmayan &uuml;r&uuml;n kategorilerinin belirlenmesi),</span></p>
            <p><span>Alışveriş tercihlerinin analiz edilmesi suretiyle modellemeler yapılarak belirli bir &uuml;r&uuml;n&uuml; alma potansiyeli olan m&uuml;şteri gruplarına y&ouml;nelik kampanyalar d&uuml;zenlenmesi ve sisteme y&uuml;klenmesi,</span></p>
            <p><span>Satış potansiyelinin artırılmasına y&ouml;nelik aksiyonlar alınması gibi &ccedil;alışmalar,</span></p>
            <p><strong><span>KVK Kanununda belirtilen kişisel veri işleme şartlarına uygun olarak ve işbu Aydınlatma Metninde belirtilen hukuki sebepler doğrultusunda toplamaktadır.</span></strong></p>
            <ul>
            <li><span>Kanunlarda &ouml;ng&ouml;r&uuml;lmesi </span></li>
            <li><span>6102 Sayılı T&uuml;rk Ticaret Kanunu,</span></li>
            <li><span>213 Sayılı Vergi Usul Kanunu, </span></li>
            <li><span>6698 Sayılı Kişisel Verileri Koruma Kanunu,</span></li>
            <li><span>4721 Sayılı T&uuml;rk Medeni Kanunu,</span></li>
            <li><span>6098 Sayılı Bor&ccedil;lar Kanunu,</span></li>
            <li><span>6502 Sayılı T&uuml;keticinin Korunması Hakkında Kanun,</span></li>
            <li><span>6502 sayılı T&uuml;keticinin Korunması Hakkında Kanun,</span></li>
            <li><span>Mesafeli S&ouml;zleşmeler Y&ouml;netmeliği,</span></li>
            <li><span>5651 Sayılı İnternet Ortamında Yapılan Yayınların D&uuml;zenlenmesi ve Bu Yayınlar Yoluyla İşlenen Su&ccedil;larla M&uuml;cadele Edilmesi Hakkında Kanun,</span></li>
            <li><span>İlgili kişinin temel hak ve &ouml;zg&uuml;rl&uuml;klerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri i&ccedil;in veri işlenmesinin zorunlu olması.</span></li>
            <li><span>Bir s&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla (bağımsız denetim hizmeti verdiğimiz m&uuml;şterilerimizin sağladığı kişisel veriler bu kapsamdadır), s&ouml;zleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması,</span></li>
            <li><span>Talep edilen &uuml;r&uuml;n ve hizmetleri sunabilmek ve akdettiğiniz s&ouml;zleşmelerinin gereğinin yerine getirilmesi,</span></li>
            <li><span>Hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n yerine getirebilmesi i&ccedil;in zorunlu olması,</span></li>
            <li><span>Bir hakkın tesisi, kullanılması veya korunması i&ccedil;in veri işlemenin zorunlu olması,</span></li>
            <li><span>Yasal mevzuat &ccedil;er&ccedil;evesinde yukarıda belirtilen ama&ccedil;larla, s&ouml;zleşmenin ifası (6502 sayılı T&uuml;keticinin Korunması Hakkında Kanun ve Mesafeli S&ouml;zleşmeler Y&ouml;netmeliği'nden doğan y&uuml;k&uuml;ml&uuml;l&uuml;klerimizin ifası),</span></li>
            </ul>
            <p><strong><span>İlgili Kişiler&rsquo;in Elektronik Ticari İleti Alma Konusundaki Olumlu veya Olumsuz Tercihlerini Nasıl Değiştirebilecekleri</span></strong></p>
            <p><span>Profilleme ve segmentasyon &ccedil;alışmaları kapsamında M&uuml;şteri/&Uuml;ye&rsquo;nin kişisel verileri &ouml;zellikle ad ve soyadı, cep telefonu, e-postası veya adres bilgisi doğrudan kullanılmamakta, bunun yerine kendilerine atanan M&uuml;şteri/&Uuml;ye ID&rsquo;leri ile işlem yapılmaktadır. M&uuml;şteri ID&rsquo;si veya diğer bir ifade ile takma adlı veri kullanımı sayesinde M&uuml;şteri/&Uuml;ye&rsquo;nin kişisel verilerinin korunması sağlanmaktadır. M&uuml;şteri/&Uuml;ye ID&rsquo;leri OTTO SOLMAZ i&ccedil;inde sadece ilgili kişi veya departmanların erişimine a&ccedil;ıktır. M&uuml;şteri/&Uuml;ye&rsquo;ye atanan bu ID&rsquo;ler OTTO SOLMAZ tarafından sistem i&ccedil;inde şifreli olarak muhafaza edilmekte ve bu b&ouml;l&uuml;me erişim yine sadece sınırlı kişilere tanınmaktadır.</span></p>
            <p><span>OTTO SOLMAZ tarafından işletilen elektronik ticaret platformlarının web sitesi veya mobil uygulamasına &uuml;ye olurken veya daha sonraki bir zamanda vermiş olduğunuz ticari elektronik ileti alma konusundaki olumlu veya olumsuz tercihlerinizi dilediğiniz zaman &ldquo;Hesabım&rdquo; b&ouml;l&uuml;m&uuml;ne erişerek değiştirebilir veya g&uuml;ncelleyebilirsiniz.</span></p>
            <p><span>&Uuml;yeliğin sona erdirilmesi, ticari elektronik ileti alma konusunda verdiğiniz onayın geri alınması anlamına gelmemektedir. Bu nedenle ayrıca verdiğiniz onayı geri almaya ilişkin t&uuml;m işlemleri tamamladığınızdan emin olunuz.</span></p>
            <p><strong><span>Resmi Makamlarla Kişisel Veri Paylaşımı</span></strong></p>
            <p><span>OTTO SOLMAZ, tarafından işletilen elektronik ticaret platformlarına yaptığınız ziyaret veya &uuml;yeliğe ilişkin kişisel verilerinizi ve gezinme bilgileriniz gibi trafik bilgilerinizi; OTTO SOLMAZ&rsquo;ın yasalar karşısındaki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; ifa etmesi amacıyla (su&ccedil;la m&uuml;cadele, devlet ve kamu g&uuml;venliğinin tehdidi ve benzeri ancak bununla sınırlı olmamak &uuml;zere OTTO SOLMAZ&rsquo;ın yasal veya idari olarak bildirim veya bilgi verme y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n mevcut olduğu durumlarda) yasal olarak bu bilgileri talep etmeye yetkili olan kamu kurum ve kuruluşları ile paylaşabilecektir.</span></p>
            <p><strong><span>Kişisel Verilerin Korunması Politikası ve Aydınlatma Metninde Yapılacak Değişiklikler</span></strong></p>
            <p><span>OTTO SOLMAZ, Kişisel Verilerin Korunması Politikası ve iş bu Aydınlatma Metninde her zaman değişiklik yapabilir. Bu değişiklikler, değiştirilmiş yeni Aydınlatma Metninin yayınlanmasıyla birlikte derhal ge&ccedil;erlilik kazanır. İşbu Aydınlatma Metninde ger&ccedil;ekleşecek değişikliklerden haberdar olmanız i&ccedil;in, siz &uuml;yelerimize gerekli bilgilendirme yapılacaktır.</span></p>
            <p><strong><span>Kişisel Veri Sahibinin 6698 sayılı Kanun&rsquo;un 11. Maddesinde Sayılan Hakları</span></strong></p>
            <p><span>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi aşağıda d&uuml;zenlenen y&ouml;ntemlerle iletmeniz durumunda OTTO SOLMAZ talebin niteliğine g&ouml;re talebi en kısa s&uuml;rede ve en ge&ccedil; otuz g&uuml;n i&ccedil;inde sonu&ccedil;landıracaktır. Verilecek cevapta on sayfaya kadar &uuml;cret alınmayacaktır. On sayfanın &uuml;zerindeki her sayfa i&ccedil;in 1 T&uuml;rk Lirası işlem &uuml;creti alınacaktır. Başvuruya cevabın CD, flash bellek gibi bir kayıt ortamında verilmesi halinde şirketimiz tarafından talep edilebilecek &uuml;cret kayıt ortamının maliyetini ge&ccedil;meyecektir.</span></p>
            <p><span>Bu kapsamda kişisel veri sahipleri;</span></p>
            <p><span>Kişisel veri işlenip işlenmediğini &ouml;ğrenme,</span></p>
            <p><span>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</span></p>
            <p><span>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme,</span></p>
            <p><span>Yurt i&ccedil;inde veya yurt dışında kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,</span></p>
            <p><span>Kişisel verilerin eksik veya yanlış işlenmiş olması h&acirc;linde bunların d&uuml;zeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</span></p>
            <p><span>6698 sayılı Kanun ve ilgili diğer kanun h&uuml;k&uuml;mlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,</span></p>
            <p><span>İşlenen verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya &ccedil;ıkmasına itiraz etme,</span></p>
            <p><span>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme haklarına sahiptir.</span></p>
            <p><span>Yukarıda belirtilen haklarınızı kullanma ile ilgili talebinizi, 6698 sayılı Kanunu&rsquo;nun 13. maddesinin 1. fıkrası ve 30356 sayılı ve 10.03.2018 tarihli Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ gereğince T&uuml;rk&ccedil;e ve yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, g&uuml;venli elektronik imza, mobil imza ya da OTTO SOLMAZ&rsquo;a daha &ouml;nce bildirilen ve sistemimizde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle iletebilirsiniz. OTTO SOLMAZ&rsquo;ın cevap vermeden &ouml;nce kimliğinizi doğrulama hakkı saklıdır.</span></p>
            <p><span>Başvurunuzda;</span></p>
            <p><span>Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,</span></p>
            <p><span>T&uuml;rkiye Cumhuriyeti vatandaşları i&ccedil;in T.C. kimlik numaranızın, yabancı iseniz uyruğunuzun, pasaport numaranızın veya varsa kimlik numaranızın,</span></p>
            <p><span>Tebligata esas yerleşim yeri veya iş yeri adresinizin,</span></p>
            <p><span>Varsa bildirime esas elektronik posta adresi, telefon ve faks numaranızın,</span></p>
            <p><span>Talep konunuzun, bulunması zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de başvuruya eklenmesi gerekmektedir.</span></p>
            <p><span>Posta yolu ile yapmak istediğiniz başvurularınızı, veri sorumlusu olarak ottosolmaz.com Barbaros mah. Troya Cad. No:40-42 &Ccedil;anakkale / T&uuml;rkiye adresine g&ouml;nderebilirsiniz.</span></p>
            <p><span>E-posta yoluyla yapmak istediğiniz başvurularınızı <a href="mailto:kvkk@ottosolmaz.com">kvkk@ottosolmaz.com</a> &nbsp;e-posta adresine veya <a href="mailto:ottosolmaz@hs03.kep.tr">ottosolmaz@hs03.kep.tr</a> &nbsp;Kayıtlı Elektronik Posta adresine yapabilirsiniz.</span></p>
            <p><span>&nbsp;</span></p>
            <p><strong><u><span>VERİ SORUMLUSU İLETİŞİM BİLGİLERİ</span></u></strong></p>
            <p><strong><span>OTTO SOLMAZ AYAKKABI DERİ &Uuml;R. TUR. SAN. DIŞ TİC. DNŞ. LTD. ŞTİ.</span></strong></p>
            <p><strong><u><span>ADRES:</span></u><span> Barbaros Mahallesi Troya Caddesi No: 40-42 Merkez/&Ccedil;ANAKKALE</span></strong></p>
            <p><strong><u><span>MERSİS NO:</span></u><span> 0649430026000027</span></strong></p>
            <p><strong><u><span>E-POSTA:</span></u> </strong><span><a href="mailto:kvkk@ottosolmaz.com"><strong>kvkk@ottosolmaz.com</strong></a></span></p>
            <p><strong><u><span>KEP ADRESİ:</span></u> </strong><span><a href="mailto:ottosolmaz@hs03.kep.tr"><strong>ottosolmaz@hs03.kep.tr</strong></a><br /><br /><br /><a target="_blank" href="http://img.ayakkabionline.com/site/files/veri-sahibi-basvuru-formu.pdf" title="Veri Sahibi Başvuru Formu"><b class="tinymceVarsayilan">Veri Sahibi Başvuru Formuna Ulaşmak İ&ccedil;in Tıklayın</b></a></span><span><strong><br /></strong></span></p>
        </div>
    )
}