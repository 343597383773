import React from "react";

export default function About() {
    return (
        <div className="about">
            <table className="table table-bordered">
                <tr>
                    <td colSpan="2" className="title">Şirket Bilgileri</td>
                </tr>
                <tr>
                    <th>Ticaret Ünvanı</th>
                    <td>Otto Solmaz Ayakkabı Deri Ür. Tur. San. Dış Tic. Dnş. Ltd. Şti.</td>
                </tr>
                <tr>
                    <th>Adres</th>
                    <td>Barbaros Mahallesi Troya Caddesi No: 40-42 Merkez/Çanakkale</td>
                </tr>
                <tr>
                    <th>Vergi Dairesi</th>
                    <td>Çanakkale</td>
                </tr>
                <tr>
                    <th>Vergi Numarası</th>
                    <td>649 043 0026</td>
                </tr>
                <tr>
                    <th>Mersis No</th>
                    <td>0649043002600015</td>
                </tr>
                <tr>
                    <td colSpan="2" className="title">İletişim Bilgileri</td>
                </tr>
                <tr>
                    <th>Kep Adresi</th>
                    <td>ottosolmaz@hs03.kep.tr</td>
                </tr>
                <tr>
                    <th>Telefon</th>
                    <td>0286 217 0202</td>
                </tr>
                <tr>
                    <th>Fax</th>
                    <td>0286 217 0545</td>
                </tr>
                <tr>
                    <th>İnternet Sitesi</th>
                    <td>www.ottosolmaz.com</td>
                </tr>
            </table>
        </div>
    )
}