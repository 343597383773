import React from "react";
import ayakkabionline from '../assets/img/site/ayakkabionline.png';
import alisolmaz from '../assets/img/site/alisolmaz.png';
import modarex from '../assets/img/site/modarex.png';

export default function Home() {
    return (
        <div className="container row siteList">
            <div className="col col-6 col-xs-12">
                <a href="https://www.ayakkabionline.com" target="_blank">
                    <img src={ayakkabionline} />
                    ayakkabionline.com
                </a>
            </div>
            <div className="col col-6 col-xs-12">
                <a href="https://www.alisolmaz.com" target="_blank">
                    <img src={alisolmaz} />
                    alisolmaz.com
                </a>
            </div>
            <div className="col col-6 col-xs-12">
                <a href="https://www.modarex.com" target="_blank">
                    <img src={modarex} />
                    modarex.com
                </a>
            </div>
        </div>
    )
}